.certificate {
    max-width: 600px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: auto;
  }
  
  .logo {
    max-width: 100%;
    height: auto;
  }
  
  h2 {
    color: #333333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
  }
  
  .label {
    color: #555555;
    font-weight: bold;
  }
  
  .value {
    flex-grow: 1;
    margin-left: 10px;
    font-weight: bold;
  }
  
  .pass {
    color: #2ecc71; /* Green color for Pass */
  }
  
  .score {
    color: #3498db; /* Blue color for Score */
  }
  
  .interpretation {
    color: #555555;
    font-style: italic;
  }
  
  @media (max-width: 768px) {
    .certificate {
      padding: 10px;
    }
  
    h2 {
      font-size: 20px;
    }
  }
  